<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img class="left-logo" src="../assets/logosmall.png" alt="" />
        <span>企业CRM管理平台</span>
      </div>
      <div>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-circle-check" @click.native="changePassword"
              >密码修改</el-dropdown-item
            >
            <el-dropdown-item icon="el-icon-circle-check" @click.native="logout"
              >退出系统</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <!-- 左侧菜单区域 -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <!-- 侧边栏菜单 -->
        <el-menu
          background-color="#304156"
          text-color="#fff"
          active-text-color="#4699F2"
          unique-opened
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="activePath"
        >
          <el-submenu
            :index="item.MenuName"
            v-for="item in menuList"
            :key="item.MenuName"
          >
            <template slot="title">
              <i :class="item.IconSet"></i>
              <span class="menu-left">{{ item.MenuName }}</span>
            </template>
            <el-menu-item
              :index="subItem.Path"
              v-for="subItem in item.ChildrenMenuList"
              :key="subItem.MenuName"
              @click="saveNavState(subItem.Path)"
            >
              <i class="el-icon-menu"></i>
              <span>{{ subItem.MenuName }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 主体区域 -->
      <el-main>
        <!-- 路由占位符 -->
         <router-view />
        <!-- <transition name="el-fade-in">
         
        </transition> -->
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 左侧菜单数据
      menuList: [],
      // 左侧菜单是否折叠
      isCollapse: false,
      activePath: "",
      userName: "默认用户",
    };
  },
  created() {
    //当用name 路由形式传递参数时，使用params
    //let roleid = this.$route.params.roleid;
    // let roleid = this.$route.query.roleid;
    let roleid = window.sessionStorage.getItem("roleId");
    this.getMenuList(roleid);
    this.activePath = window.sessionStorage.getItem("activePath");
    this.userName = window.sessionStorage.getItem("userName");
  },
  methods: {
    logout() {
      // 清空sessionStorge
      window.sessionStorage.clear();
      // 路由导航到登录页
      this.$router.push("/login");
    },
        changePassword() {
      this.$router.push("/changepassword");
    },
    /*
    GET 请求及传参
    this.$http.get('get.php',{params:{a:10,b:2}}).then(function(res){
                            console.log(res);
                            console.log(res.data);
                        },function(res){
                            console.log('失败')
                        })
    POST 请求及传参
     //post方法有三个参数post("php文件"，"参数","emulateJSON:true")
                //emulateJSON:true  模拟一个JSON数据发送到服务器，这样才可以成功运行 
                        this.$http.post('post.php',{a:10,b:2},{emulateJSON:true}).then(function(res){
                            console.log(res);
                            console.log(res.data);
                        },function(res){
                            console.log('失败')
                        })
    */
    async getMenuList(roleid) {
      await this.$http.post("Menu/GetMenuList", { roleid: roleid }).then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);
          this.menuList = res.data.Obj;
          //   console.log(res.data.Obj);
        },
        function (res) {
          this.$message.error("远程获取数据失败");
        }
      );
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    // 保存激活菜单的值
    saveNavState(activePath) {
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
  },
};
</script>
<style lang="less" scoped>
.el-header {
  // background-color: #2c3b4d;
  background-color: #576f8b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  > div {
    display: flex;
    align-items: center;
    img {
      height: 40px;
      height: 40px;
    }
    span {
      margin-left: 15px;
    }
  }
}
.el-aside {
  background-color: #304156;
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #eaedf2;
}
.home-container {
  height: 100%;
}
.toggle-button {
  background-color: #485164;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.menu-left{
  margin-left:10px;
}
</style>