<template>
  <div class="login_container">
    <div class="login_box">
      <div class="avatar_box">
        <img src="../assets/logosmall.png" alt="" />
      </div>
       <div class="login_title">企业CRM管理平台</div>
      <el-form ref="loginFormRef" :rules="loginFormRules" :model="loginForm" label-width="0px" class="login_form">
        <!-- 用户名 -->
        <el-form-item  prop="LoginName">
          <el-input v-model="loginForm.LoginName" prefix-icon="iconfont iconuser"></el-input>
        </el-form-item>
         <!-- 密码 -->
        <el-form-item prop="UserPassword">
          <el-input type="password" v-model="loginForm.UserPassword" prefix-icon="iconfont iconlock"></el-input>
        </el-form-item>
          <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
     <div class="support_box">&copy;2046 技术支持：北京千悦源科技有限公司</div>
  </div>
 
</template>

<script>
export default {
  data(){
    return{
      loginForm:{
        LoginName:'',
        UserPassword:''
      },
      loginFormRules:{
         LoginName: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
          ],
          UserPassword: [
            { required: true, message: '请输入面密码', trigger: 'blur' },
            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
          ],
      }
    }
  },
  methods:{
    // 重置表单按钮
    resetLoginForm(){
     this.$refs.loginFormRef.resetFields();
    },
    login(){
      this.$refs.loginFormRef.validate(async (valid)=>{
        if(!valid)return;
        const {data:res}=await this.$http.post('user/UserLogin',this.loginForm);

        if(res.Code!=='200') {
          return this.$message.error(res.Message);
         
          }
        this.$message.success(res.Message);
         
        window.sessionStorage.setItem('userCode',res.Obj.StaffNewsId);
        window.sessionStorage.setItem('userName',res.Obj.UserName);
        window.sessionStorage.setItem('companyCode',res.Obj.CompanyCode);
        window.sessionStorage.setItem('roleId',res.Obj.RoleId);
        window.sessionStorage.setItem('token',"wygwyg");//没有从后台给token，则默认一个字符串
        window.sessionStorage.setItem('isSale',res.Obj.IsSale);
       
        this.$router.push('/home').catch(err=>{
          console.log(err);
        });
        //this.$router.push({name: 'test', params: {html: this.html}})
        //this.$router.push({path: '/details', query: {data: data}})
        //传参数的用法
        // this.$router.push({path: '/home', query: {roleid: res.Obj.RoleId}})
      });
    }
  }
};
</script>

<style lang="less" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100%;
}
.support_box{
  position: absolute;
  left: 42%;
  bottom: 10%;
  color:#ffffff;
  font-size:12px;
}
.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* x轴，y轴各偏移50% */
  .avatar_box {
    height: 110px;
    width: 110px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}
.btns{
  display: flex;
  justify-content: flex-end;
}
.login_form{
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.login_title{
  text-align: center;
   font-size: 20px;
   width: 400px;
  font-weight: 600;
  position: absolute;
  left: 50%;
  top:25%;
  transform: translate(-50%);
  color:#2b4b6b;
}
</style>
