<template>
    <div>
       欢迎您登录企业CRM管理平台
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>


</style>